import { useCallback } from 'react';
import {
  addAnonymousLikedProduct,
  removeAnonymousLikedProducts,
} from '../state/anonymousLikedProducts/anonymousLikedProducts';
import { useDispatch, useSelector } from 'react-redux';
import { useUserProfile } from './useUserProfile';
import { isMPGTMEnabled } from './isGTMEnabled';
import useNotifications from '../components/NotificationPopup/useNotifications';
import { useIntl } from 'react-intl';
/**
 * useWishlist hook exposes public wishlist properties and methods to be used in app
 * @param {boolean} isDetailedWishlist - set to false to convert to plan array with product id's
 * @returns wishlist - current user wishlist (anonymous/authorised), wishlistProducts - products info from search api, isWishlistItem, toggleWishlistItem, saveToWishlist, removeFromWishlist
 */
const useWishlist = (isDetailedWishlist = false) => {
  //wishlistDetailed and wishlistPlain are synced versions based on same data. They are updated in useWishlistUpdater hook
  const wishlistDetailed = useSelector(({ wishlist }) => wishlist?.wishlistItems);
  const wishlistPlain = useSelector(({ wishlist }) => wishlist?.wishlistPlain);
  //wishlistProducts is an array with product data of wishlistPlain id's
  const wishlistProducts = useSelector(({ wishlist }) => wishlist?.wishlistProducts);
  const profile = useSelector(({ profile }) => profile);
  const profilePresent = !!profile;
  const intl = useIntl();

  const { deleteWishlistProduct, addWishlistProduct } = useUserProfile();
  const dispatch = useDispatch();
  const { sendNotification } = useNotifications();

  const saveToAnonymousWishlist = (id) => {
    if (id.length !== 32 || !id.match(/^[a-z0-9]+$/gi)) {
      console.error('Can not add product to wishlist. Ids should be alphanum value of exact 32 character length');
      sendNotification(intl.formatMessage({ id: 'wishlist.cannotAdd' }));
      return;
    }
    dispatch(addAnonymousLikedProduct(id));
  };

  const removeFromAnonymousWishlist = (id) => {
    dispatch(removeAnonymousLikedProducts(id));
  };

  const saveToWishlist = (id) => {
    if (isMPGTMEnabled) window.dataLayer.push({ event: 'add_to_wishlist', product_id: id });

    if (!profilePresent) {
      saveToAnonymousWishlist(id);
      return;
    }

    addWishlistProduct({
      body: JSON.stringify({ id }),
      method: 'POST',
    })
      .then((resp) => {
        if (!resp.ok) sendNotification(intl.formatMessage({ id: 'wishlist.cannotAdd' }));
      })
      .catch(() => {
        sendNotification(intl.formatMessage({ id: 'wishlist.cannotAdd' }));
      });
  };

  const removeFromWishlist = (id) => {
    if (isMPGTMEnabled) window.dataLayer.push({ event: 'remove_from_wishlist', product_id: id });

    if (!profilePresent) {
      removeFromAnonymousWishlist(id);
      return;
    }

    deleteWishlistProduct({
      body: JSON.stringify({ id }),
      method: 'POST',
    });
  };

  const isWishlistItem = useCallback(
    (pid) => {
      if (!wishlistPlain?.length) return false;
      return wishlistPlain.includes(pid);
    },
    [wishlistPlain]
  );

  const toggleWishlistItem = (productId) => {
    const isItemPresent = isWishlistItem(productId);
    if (isItemPresent) removeFromWishlist(productId);
    else saveToWishlist(productId);
  };

  return {
    wishlist: isDetailedWishlist ? wishlistDetailed : wishlistPlain,
    wishlistProducts,
    isWishlistItem,
    toggleWishlistItem,
    saveToWishlist,
    removeFromWishlist,
  };
};

export default useWishlist;
