import React, { useState } from 'react';

import { ReactComponent as FemaleIcon } from '../../images/icons/female.svg';
import { ReactComponent as MaleIcon } from '../../images/icons/male.svg';
import { ReactComponent as PlaceholderIcon } from '../../images/icons/userPlaceholder.svg';
import styles from './Avatar.module.scss';
import classNames from 'classnames';

const Avatar = ({ src, salutation, classList = {} }) => {
  const [error, setError] = useState(null);

  if (error) {
    if (salutation === 'mr') {
      return <MaleIcon className={classNames(styles.avatarDefault, classList.animation)} />;
    }

    if (salutation === 'mrs') {
      return <FemaleIcon className={classNames(styles.avatarDefault, classList.animation)} />;
    }

    return <PlaceholderIcon className={classNames(styles.avatarDefault, classList.animation)} />;
  }
  if (!src) {
    return <PlaceholderIcon className={classNames(styles.avatarDefault, classList.animation)} />;
  }
  return (
    <img src={src} alt="avatar" onError={setError} className={classNames(styles.avatarDefault, classList.animation)} />
  );
};

Avatar.displayName = 'Avatar';
export default Avatar;
